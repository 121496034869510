import React, { useEffect, useMemo, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {
  Block,
  DataTable,
  DataTableBody,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  BlockDes,
  RSelect,
  TooltipComponent,
  PaginationComponent,
  Row,
  Col,
} from "../../components/Component";
import { Languages, activeStatus } from "../../utils/envConfig";
import "react-phone-number-input/style.css";
import LoadingComponent from "../../pages/components/loader/tableLoader";
import AgentAvatar from "./components/AgentAvatar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { fetchAgentsPaginated } from "../../redux/actions/user";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import DndFlagComponent from  '../components/dnd/dndFlag.js'
const AgentList = () => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const { paginatedAgents: data, loading } = useSelector((state) => state.user);
  const FIELDS = ["Mobile No", "Email", "Status", "Managed By"];
  const [leaderOptions, setLeaderOptions] = useState([]);
  const status = ["All", "Active", "Inactive"];
  const statusOptions = status.map((c) => ({
    label: c,
    value: c,
  }));
  const [sm, updateSm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const toggleSearch = () => setonSearch(!onSearch);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [filter, setFilter] = useState({
    active: true,
  });
  const [selectedTeams, setselectedTeams] = useState([])
  const searchRequest = (val) => {
    setFilter((prev) => ({
      ...prev,
      search: val,
    }));
  };
  const debounceSearch = useMemo(() => {
    return debounce(searchRequest, 1000);
  }, [searchRequest]);
  const [actionModal, setActionModal] = useState({
    isOpen: false,
    agent: null,
    leaderOptions,
  });
  const fetchLeaders = async () => {
    try {
      const { data } = await axios.get(`/agent/filter`, {
        params: {
          options: {
            limit: 50,
          },
          filter: {
            isLeader: true,
          },
        },
      });
      setLeaderOptions((c) =>
        data?.agents?.map((c) => ({
          ...c,
          label: `Team: ${c?.user?.first_name} ${c?.user?.last_name}`,
          value: c?.userId,
        }))
      )
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    delete filter?.leader
    dispatch(
      fetchAgentsPaginated(axios, {
        options: {
          limit,
          page,
        },
        filter,
      })
    );
  }, [limit, page, filter, refresh,axios,dispatch]);
  useEffect(() => {
    fetchLeaders();
  }, [refresh]);
  return (
    <React.Fragment>
      <Head title="Agents"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                List Agents
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {data?.totalCount} Agents.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="position-relative gap-3 py-5 d-flex">
          <div style={{ minWidth: "20%" }}>
            <RSelect
              onChange={(e) => {
                if (e.length) {
                  setFilter((c) => ({
                    ...c,
                    leaderId: e?.map((x) => x?.userId),
                    leader: e?.map((x) => x)
                  }));
                } else {
                  setFilter((c) => {
                    delete c?.leaderId;
                    delete c?.leader
                    setselectedTeams([])
                    return { ...c };
                  });
                }
                setselectedTeams(e)
              }}
              value={selectedTeams?.length?selectedTeams:null}
              options={leaderOptions}
              isMulti
              placeholder="Filter By Team"
            />
          </div>
          
          <div style={{ width: "10%" }}>
            <RSelect
              onChange={(e) => {
                if (e.value === status[1]) {
                  return setFilter((c) => ({ ...c, active: true }));
                } else if (e.value === status[2]) {
                  return setFilter((c) => ({ ...c, active: false }));
                } else {
                  setFilter((c) => {
                    delete c?.active;
                    return { ...c };
                  });
                }
                setPage(1);
              }}
              value={filter?.active?statusOptions[1]:(filter?.active===false?statusOptions[2]:null)}
              options={statusOptions}
              placeholder="Status"
            />
          </div>
          <div  className="mt-3 text-end   cursor-pointer">
        {(filter?.active||filter?.active===false||selectedTeams.length)?<Badge onClick={(e)=>{
          setselectedTeams([])
          setFilter({})}} color="danger"><Icon name={"cross-sm"}/>Clear Filters</Badge>:null}
        </div>
       
        </div>
       
        <Block>
          <DataTable>
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools"></div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleSearch();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => updateTableSm(false)}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!onSearch && "active"}`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        toggleSearch();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                      placeholder="Search User by name or email or phone"
                      onChange={(e) => debounceSearch(e.target.value)}
                    />
                    <Button className="search-submit btn-icon  bg-transparent text-secondary border-0">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead className="nk-tb-item">
                <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="pid-all"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="pid-all"
                    ></label>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <span>Name</span>
                </DataTableRow>
                {FIELDS.map((option, id) => (
                  <DataTableRow key={option + id} className={"text-center"}>
                    <span>{option}</span>
                  </DataTableRow>
                ))}
                <DataTableRow className="nk-tb-col-tools">
                  <ul className="nk-tb-actions gx-1 my-n1"></ul>
                </DataTableRow>
              </DataTableHead>
              {loading && <LoadingComponent />}
              {data?.agents?.length
                ? data?.agents
                    ?.map((item, index) => (
                      <TabelItem
                      key={item?.userId}
                        agent={item}
                        index={index}
                        onEdit={(agent) =>
                          setActionModal({
                            agent,
                            isOpen: true,
                            type: "edit",
                          })
                        }
                      />
                    ))
                : null}
            </DataTableBody>
            {!loading && !data?.agents?.length && (
              <div className="py-4  px-5 text-center fw-bold w-100">
                No Data
              </div>
            )}
            <div className="my-2">
              <PaginationComponent
                currentPage={page}
                itemPerPage={limit}
                paginate={(page) => setPage((c) => page)}
                totalItems={data?.searchCount}
              />
            </div>
          </DataTable>
        </Block>
      </Content>
      <ConfirmModal
        refresh={() => setRefresh((c) => !c)}
        agent={actionModal.agent}
        axios={axios}
        isOpen={actionModal.isOpen}
        leaderOptions={leaderOptions}
        toggleModal={() =>
          setActionModal((c) => ({
            ...c,
            isOpen: false,
            leaderOptions,
            agent: null,
          }))
        }
        type={"edit"}
      />
    </React.Fragment>
  );
};
const TabelItem = ({ agent, index, onEdit }) => {
  const navigate=useNavigate()
  return (
    <DataTableItem key={index} style={{ height: "70px" }}>
      <DataTableRow className="nk-tb-col-check">
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input
            type="checkbox"
            className="custom-control-input"
            defaultChecked={agent.check}
            id={index + "oId-all"}
            key={Math.random()}
          />
          <label
            className="custom-control-label"
            htmlFor={index + "oId-all"}
          ></label>
        </div>
      </DataTableRow>
      <DataTableRow>
        <div className="d-flex gap-2">
          <AgentAvatar agent={agent} />
          <Link to={`/user-profile/${agent?.userId}/user-profile-setting`}>
            {agent?.user?.first_name} {agent?.user?.last_name}
          </Link>
        </div>
      </DataTableRow>
      <DataTableRow className={"text-center"}>
        {agent?.user?.DndStatus ? (
          <DndFlagComponent
            className={
              "d-flex flex-column align-items-center justify-content-center p-2"
            }
            lead_status={agent?.user?.lead_status}
            phoneNumber={agent?.user?.phone}
          />
        ) : (
          <span>{agent?.user?.phone}</span>
        )}
      </DataTableRow>
      <DataTableRow className={"text-center"}>
        <span>{agent.user.email}</span>
      </DataTableRow>
      <DataTableRow className={"text-center"}>
        <Badge color={agent?.user?.active ? "success" : "danger"}>
          {agent?.user?.active ? activeStatus[0] : activeStatus[1]}
        </Badge>
      </DataTableRow>
      <DataTableRow className={"text-center"}>
        {agent?.isLeader ? (
          <span className="bg-secondary badge badge-dot has-bg pill">
            Leader
          </span>
        ) : (
          <>
            {agent?.leader ? (
              <div className="d-flex gap-2 align-content-center justify-content-center">
                <AgentAvatar
                  agent={{
                    ...agent,
                    user: agent?.leader,
                    userId: agent?.leader?.userId,
                  }}
                />
                <Link
                  to={`/user-profile/${agent?.leader?.userId}/user-profile-setting`}
                >
                  {agent?.leader?.first_name} {agent?.leader?.last_name}
                </Link>
              </div>
            ) : (
              <span>N/A</span>
            )}
          </>
        )}
      </DataTableRow>
      <DataTableRow className="nk-tb-col-tools">
        <ul className="nk-tb-actions gx-1">
          <li
            onClick={(e) =>
              navigate(`/user-profile/${agent?.userId}/user-profile-setting`)
            }
            className="nk-tb-action-hidden"
          >
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger  btn-icon"
              id={"view-details"}
              icon={"eye"}
              iconClass={`text-primary`}
              direction="top"
              text={"View Agent Details"}
            />
          </li>

          <li onClick={(e) => onEdit(agent)} className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger  btn-icon"
              id={"edit-details"}
              icon={"edit"}
              iconClass={`text-secondary`}
              direction="top"
              text={"Edit Agent Details"}
            />
          </li>
        </ul>
      </DataTableRow>
    </DataTableItem>
  );
};
const ConfirmModal = ({
  type,
  agent,
  axios,
  isOpen,
  toggleModal,
  leaderOptions,
  refresh,
}) => {
  const isEdit = type?.includes("edit");
  const [formData, setFormData] = useState({
    leader: agent?.leader
      ? {
          ...agent?.leader,
          label: `${agent?.leader?.first_name} ${agent?.leader?.last_name}`,
        }
      : null,
    active: agent?.user?.active,
    isLeader: agent?.isLeader,
    languages: agent?.languages?.map((c) => ({
      labe: c,
      value: c,
    })),
    RERANo: agent?.RERANo,
    PERMITNo: agent?.PERMITNo,
    DEDNo: agent?.DEDNo,
    agentBio: agent?.agentBio
  });
  useEffect(() => {
    setFormData({
      leader: agent?.leader
        ? {
            ...agent?.leader,
            label: `${agent?.leader?.first_name} ${agent?.leader?.last_name}`,
          }
        : null,
      active: agent?.user?.active,
      isLeader: agent?.isLeader,
      languages: agent?.languages?.map((c) => ({
        label: c,
        value: c,
      })),
      RERANo: agent?.RERANo,
      PERMITNo: agent?.PERMITNo,
      DEDNo: agent?.DEDNo,
      agentBio: agent?.agentBio,
    });
  }, [agent]);

  const [loading, setloading] = useState(false);
  const handleEdit = async () => {
    try {
      setloading(true);
      await axios.patch(`/agent/${agent?._id}`, {
        ...formData,
        leaderId: formData.leader?.userId,
        languages: formData.languages
          ?.map((c) => c?.value)
          ?.filter((c) => c !== undefined),
      });
      toast.success("Agent Edited Successfullly");
      refresh();
      toggleModal();
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };
  const languageOptions = Languages.map((c) => ({
    label: c,
    value: c,
  }));
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <h6 className="title">
          {isEdit ? "Edit Agent Details" : "Delete Agent"}
        </h6>
      </ModalHeader>  
      <ModalBody>
      <Col className={"mb-3 mt1 text-10px"}><Icon className={"text-info"} name={"info"}/>Go To <Link to={'/list-staff'}>Staff Page</Link> To Disable Agent Or Edit more Details</Col>
        <Col className={"p-2 d-flex align-items-center mb-3"}>
          <AgentAvatar agent={agent} />
          <div className="d-flex flex-column mt-2">
            <span>{`${agent?.user?.first_name} ${agent?.user?.last_name}`}</span>
            <span className="text-10px"> {agent?.user?.email}</span>
          </div>
        </Col>
        {isEdit ? (
          <form onSubmit={(e) => {}}>
            <Row className="g-3">
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="regular-price">
                    Languages
                  </label>

                  <div className="form-control-wrap">
                    <RSelect
                      isMulti
                      options={languageOptions}
                      onChange={(e) =>
                        setFormData((c) => ({ ...c, languages: e }))
                      }
                      className="customInput"
                      value={formData.languages}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="sale-price">
                    RERA Number
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      className="form-control"
                      name="salePrice"
                      value={formData.RERANo}
                      onChange={(e) =>
                        setFormData((c) => ({ ...c, RERANo: e.target.value }))
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="stock">
                    PERMIT Number
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      className="form-control"
                      name="stock"
                      value={formData.PERMITNo}
                      onChange={(e) =>
                        setFormData((c) => ({ ...c, PERMITNo: e.target.value }))
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="SKU">
                    DED Number
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="sku"
                      value={formData.DEDNo}
                      onChange={(e) =>
                        setFormData((c) => ({ ...c, DEDNo: e.target.value }))
                      }
                    />
                  </div>
                </div>
              </Col>
              
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Agent Bio</label>
                  <textarea
                    name="notes"
                    className="form-control no-resize"
                    placeholder="Agent Bio"
                    value={formData.agentBio}
                    onChange={(e) =>
                      setFormData((c) => ({ ...c, agentBio: e.target.value }))
                    }
                  />
                </div>
              </Col>
              {!formData.isLeader ? (
                <Col md="6">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Team
                    </label>
                    <RSelect
                      options={leaderOptions}
                      onChange={(e) =>
                        setFormData((c) => ({
                          ...c,
                          leader: e,
                        }))
                      }
                      value={formData.leader}
                      placeholder="Select Team"
                    />
                  </div>
                </Col>
              ) : null}
              <Col md="6">
                <div className="custom-control mx-1 custom-control custom-switch">
                  <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch1"
                    name="leader"
                    checked={formData.isLeader}
                    onChange={(e) =>
                      setFormData((c) => ({ ...c, isLeader: e.target.checked }))
                    }
                    disabled={agent?.isLeader}
                  />
                  <label className="custom-control-label" for="customSwitch1">
                    Leader
                  </label>
                </div>
                <div className="custom-control custom-control custom-switch">
                  <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch2"
                    name="exclusive"
                    checked={formData.active}
                    onChange={(e) =>
                      setFormData((c) => ({ ...c, active: e.target.checked }))
                    }
                    disabled={true}
                  />
                  <label className="custom-control-label" for="customSwitch2">
                    Active
                  </label>
                </div>
              </Col>
            </Row>
          </form>
        ) : (
          <>Are You Sure You Want To Delete This Agent\n {agent?.phone}</>
        )}
     
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleEdit}
          disabled={loading}
          color={isEdit ? "primary" : "danger"}
        >
          {isEdit ? "Save" : "Delete"} {loading && <Spinner size={"sm"} />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default AgentList;
