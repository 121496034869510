import React, { Suspense, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
  RSelect,
  UserAvatar
} from '../../../components/Component';
import { Button, Card, CardBody, Col, Collapse, Row, Spinner } from 'reactstrap';
import LeadSourceFilter from '../../components/filter-components/LeadSourceFilter';
import CardsTable from '../../../components/table/card-table/CardsTable';
import CardsTableHeader from '../../../components/table/card-table/CardsTableHeader';
import { HEADER_FIELDS } from './constants';
import CardsTableColumn from '../../../components/table/card-table/CardsTableColumn';
import TableCheckbox from '../../../components/input/checkbox/TableCheckbox';
import CardsTableRow from '../../../components/table/card-table/CardsTableRow';
import Skeleton from 'react-loading-skeleton';
import { findUpper, getPlural, getTimeUntil, hasValue } from '../../../utils/Utils';
import { addMilliseconds } from 'date-fns';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from '../../../redux/selectors/user';
import { getPublicDocumentUrl, newRoles } from '../../../utils/envConfig';
import { Link } from 'react-router-dom';
import { ArrowDownUp } from 'lucide-react';
import CardInner from '../../../components/cards/CardInner';
import SelectAgent from '../../../components/SelectAgent';
import LeadSourceCategoryFilter from '../../components/filter-components/LeadSourceCategoryFilter';
import { showToast } from '../../../utils/toast/toast';
import LoaderModal from '../../../components/modals/LoaderModal';

const AdminAssignmentModal = React.lazy(() => import('./components/AdminAssignmentModal'));
const ConfirmationModal = React.lazy(() => import('../../../components/modals/ConfirmationModal'));

const showOptions = [
  { label: 'Show 5', value: 5 },
  { label: 'Show 10', value: 10 },
  { label: 'Show 25', value: 25 },
  { label: 'Show 50', value: 50 }
];

const ExpiredLeadsView = ({
  data,
  status,
  isFetching,
  isError,
  refetch,
  queryState,
  setQueryState,
  selectedItems,
  setSelectedItems,
  agentData,
  assignmentMutation
}) => {
  const isLoading = status === 'loading';
  const isEverythingSelected = data?.data?.length > 0 && selectedItems?.size === data?.data?.length;

  const authUser = useSelector(loggedInUserSelector);
  const isAdmin =
    authUser && (authUser.roles?.includes(newRoles.MasterAdmin) || authUser.roles?.includes(newRoles.Admin));

  const authAgent = agentData?.data;
  const isAgent = hasValue(authAgent) || authUser.roles?.includes(newRoles.TeamLeader, newRoles.AnonymousAgent);

  const handleSort = (sortField) => {
    const direction =
      queryState?.sort?.field === sortField ? (queryState?.sort?.direction === 'desc' ? 'asc' : 'desc') : 'desc';
    setQueryState((prev) => ({ ...prev, sort: { field: sortField, direction }, page: 1 }));
  };

  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const toggleAssignmentModal = () => setIsAssignmentModalOpen((prev) => !prev);

  const renderHeader = (headerData) => {
    if (headerData?.isCheckbox) {
      return (
        <TableCheckbox
          name={headerData?.name}
          id={headerData?.name}
          size={'sm'}
          checked={isEverythingSelected}
          disabled={isAgent}
          onChange={(event) => {
            event.stopPropagation();
            if (isEverythingSelected) {
              setSelectedItems((_) => new Set());
            } else {
              setSelectedItems((_) => new Set(data?.data?.map((item) => item?.id ?? item?._id)));
            }
          }}
        />
      );
    } else {
      return (
        <span>
          {headerData?.name}
          {headerData?.isSortable && (
            <ArrowDownUp size={14} className="ms-2" role="button" onClick={() => handleSort(headerData?.sortField)} />
          )}
        </span>
      );
    }
  };

  const handleLeadCategoryChange = (option) => {
    setQueryState((prev) => ({
      ...prev,
      leadSourceCategory: option,
      leadSource: option?.sources?.map((item) => ({ value: item?.name ?? item?.name, label: item?.name, ...item })),
      page: 1
    }));
  };

  const handleLeadCategoryClear = () => {
    const categorySources = (queryState?.leadSourceCategory?.sources ?? []).map((item) => item?.id ?? item?._id);
    const remainingSources = (queryState?.leadSource ?? []).filter(
      (item) => !categorySources.includes(item?.id ?? item?._id)
    );

    setQueryState((prev) => ({
      ...prev,
      leadSourceCategory: null,
      leadSource: remainingSources?.length ? remainingSources : null,
      page: 1
    }));
  };

  const handleLeadSourceChange = (options) => {
    if (options?.length) {
      setQueryState((prev) => ({
        ...prev,
        leadSource: options,
        page: 1
      }));
    } else {
      setQueryState((prev) => ({
        ...prev,
        leadSource: options,
        leadSourceCategory: null,
        page: 1
      }));
    }
  };

  const assignMultipleLeads = (leadIds, { agent_id, notes, leadSource, leadSourceName } = {}) => {
    let payload = {};

    if (isAdmin) {
      payload = {
        user_ids: leadIds,
        agent_id,
        notes,
        leadSource,
        leadSourceName,
      }
    } else {
      showToast('You are not authorized to assign leads this way', 'error');
      return;
    }

    assignmentMutation && assignmentMutation?.mutate(payload, {
      onSettled: () => {
        toggleAssignmentModal();
      }
    });
  }

  return (
    <React.Fragment>
      <Head title="Hot Leads Explorer" />
      <Content>
        <BlockHead className={'p-0'}>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>
                Expired Hot Leads Explorer
                {isLoading || isFetching ? <Spinner size={'lg'} type="grow" className="ms-4 fw-light" color="primary" /> : null}
              </BlockTitle>
              <BlockDes>
                <p className='text-muted'>You can see and pull the expired hot leads that are unassigned.</p>
              </BlockDes>
              {hasValue(authAgent) && <p className='rounded fs-6 mt-1'>You have <span className='fw-semibold text-primary'>{authAgent?.creditsBalance}</span> credits (Out of <span className='fw-bold text-primary'>{authAgent?.creditsLimit}</span>)</p>}
            </BlockHeadContent>
          </BlockBetween>
          <div className="d-flex flex-wrap justify-content-end mt-2" style={{ gap: '1rem' }}>
            <div className="w-25 position-relative">
              <SelectAgent
                selectedAgent={queryState?.agents}
                onSelectAgent={(option) => setQueryState((prev) => ({ ...prev, agents: option, page: 1 }))}
                placeholder="Select Last Agent"
                allowInactiveAgents
                notStyled
                isClearable
              />
              {hasValue(queryState?.agents) && (
                <Icon
                  name="cross"
                  className={'text-danger fw-semibold'}
                  style={{ position: 'absolute', right: '2rem', top: '.75rem', cursor: 'pointer' }}
                  onClick={() => setQueryState((prev) => ({ ...prev, agents: null, page: 1 }))}
                />
              )}
            </div>
            <div className="position-relative">
              <RSelect
                options={showOptions}
                placeholder="Show"
                value={
                  queryState?.limit ? { label: `Show ${queryState?.limit.toString()}`, value: queryState?.limit } : null
                }
                onChange={(option) => setQueryState((prev) => ({ ...prev, limit: option.value, page: 1 }))}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '8rem'
                  })
                }}
                isClearable
              />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-end mt-2" style={{ gap: '1rem' }}>
            <div className="w-25 position-relative">
              <LeadSourceFilter
                placeholder="Select Lead Source"
                stateVar={queryState?.leadSource}
                onChange={handleLeadSourceChange}
                isMulti
                hotOnly
              />
              {hasValue(queryState?.leadSource) && (
                <Icon
                  name="cross"
                  className={'text-danger fw-semibold'}
                  style={{ position: 'absolute', right: '2rem', top: '.75rem', cursor: 'pointer' }}
                  onClick={() => setQueryState((prev) => ({ ...prev, leadSource: null, leadSourceCategory: null, page: 1 }))}
                />
              )}
            </div>
            <div className="w-25 position-relative">
              <LeadSourceCategoryFilter stateVar={queryState?.leadSourceCategory} onChange={handleLeadCategoryChange} />
              {hasValue(queryState?.leadSourceCategory) && (
                <Icon
                  name="cross"
                  className={'text-danger fw-semibold'}
                  style={{ position: 'absolute', right: '2rem', top: '.75rem', cursor: 'pointer' }}
                  onClick={handleLeadCategoryClear}
                />
              )}
            </div>
          </div>
          
        </BlockHead>
        <Block className="d-flex flex-wrap mt-2">
          <Button color="primary" size="sm" onClick={() => refetch()} disabled={isFetching}>
            {isFetching ? 'Loading' : 'Refresh'}
            {isFetching ? <Spinner size={'sm'} className="ms-2" /> : <Icon name="reload" className={'ms-2'} />}
          </Button>
          <Button color="primary" size="sm" className={selectedItems?.size > 0 ? 'd-flex align-items-center ms-2' : 'd-none'} onClick={toggleAssignmentModal}>
            Assign Selected Leads
            <Icon name="check" className={'ms-2'} />
          </Button>
          {isError ? (
            <Card className="mb-2">
              <CardBody className="text-center p-4 text-danger">
                Failed to load records. <br />
                <Button className="bg-white border-0 text-primary" onClick={refetch}>
                  Retry
                </Button>
              </CardBody>
            </Card>
          ) : null}
          {!isError ? (
            <CardsTable isSeparate className="mt-1 mb-2">
              <CardsTableHeader id="hot-explorer-table-header">
                { ( isAdmin ? HEADER_FIELDS : HEADER_FIELDS.filter( header => header.name !== 'Phone')).map((header, idx) => (
                  <CardsTableColumn
                    key={`hot-explorer-column-${idx}`}
                    breakpoint={header?.breakpoint}
                    isActions={header?.isActions}
                    isCheckbox={header?.isCheckbox}
                    className={
                      header?.isActions
                        ? 'text-center'
                        : header?.first
                        ? 'text-start text-secondary fw-bold'
                        : 'text-center text-secondary fw-bold'
                    }
                  >
                    {renderHeader(header)}
                  </CardsTableColumn>
                ))}
              </CardsTableHeader>

              {isLoading
                ? new Array(queryState?.limit ?? 10).fill(0).map((_, idx1) => (
                    <CardsTableRow key={`hot-skel-row-${idx1}`}>
                      {new Array(HEADER_FIELDS.length).fill(0).map((_, idx2) => (
                        <CardsTableColumn key={`hot-skel-col-${idx1}-${idx2}`}>
                          <Skeleton />
                        </CardsTableColumn>
                      ))}
                    </CardsTableRow>
                  ))
                : null}

              {data?.data?.map((item, idx) => (
                <RecordTableItem
                  key={`table-record-${idx}`}
                  record={item}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  isAdmin={isAdmin}
                  isAgent={isAgent}
                  assignmentMutation={assignmentMutation}
                />
              ))}
            </CardsTable>
          ) : null}
          <Card className='flex-grow-1'>
            <CardInner>
              {isLoading ? (
                <div className="d-flex justify-content-between align-items-center">
                  <Skeleton width={'10rem'} height={'1rem'} />
                  <Skeleton width={'15rem'} height={'2rem'} />
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <span className="text-muted" style={{ fontSize: '.8rem' }}>
                    {`Showing ${data?.data?.length} of ${getPlural(data?.found, 'record')}`}
                  </span>
                  <div>
                    <PaginationComponent
                      currentPage={queryState?.page ?? 1}
                      itemPerPage={data?.itemsPerPage ?? 10}
                      totalItems={data?.found ?? 0}
                      paginate={(page) => {
                        setQueryState((prev) => ({ ...prev, page }));
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}
                    />
                  </div>
                </div>
              )}
            </CardInner>
          </Card>
        </Block>
      </Content>
      {isAssignmentModalOpen && (
        <Suspense fallback={<LoaderModal />}>
          <AdminAssignmentModal
            isOpen={isAssignmentModalOpen}
            toggleFn={toggleAssignmentModal}
            actionFn={assignMultipleLeads}
            loadingState={assignmentMutation?.status === 'loading'}
            userIds={Array.from(selectedItems)}
          />
        </Suspense>
      )}
    </React.Fragment>
  );
};

const RecordTableItem = ({ record, selectedItems, setSelectedItems, isAdmin, isAgent, assignmentMutation }) => {
  const [modalState, setModalState] = useState({
    admin: false,
    agent: false,
    notes: '',
    leadSource: null,
    leadSourceName: null,
  });
  const toggleModal = (type) => setModalState((prev) => ({ ...prev, [type]: !prev[type] }));

  const leadId = record?.id ?? record?._id;
  const leadSource = record?.lastExpirationRecord?.lead?.leadSource?.name;
  const leadName = record?.lastExpirationRecord?.lead?.name;
  const leadPhone = record?.lastExpirationRecord?.lead?.phone;
  const lastAgent = {
    name: record?.lastExpirationRecord?.agent?.name,
    phone: record?.lastExpirationRecord?.agent?.phone,
    photo: record?.lastExpirationRecord?.agent?.photo
  };
  const lastExpirationDate = record?.lastExpirationRecord?.createdAt;
  const numberOfExpirations = record?.numberOfExpirations;


  const assignSingleLead = (leadId, modal = 'agent', { agent_id, notes, leadSource, leadSourceName } = {}) => {
    let payload = {};

    if (isAdmin) {
      payload = {
        user_ids: [leadId],
        agent_id,
        notes,
        leadSource,
        leadSourceName,
      }
    } else if (isAgent) {
      payload = {
        leads: [leadId],
        source: 'hot-leads-explorer',
      }
    } else {
      showToast('You are not authorized to assign leads', 'error');
      return;
    }

    assignmentMutation && assignmentMutation?.mutate(payload, {
      onSettled: () => {
        toggleModal(modal);
      }
    });
  }

  return (
    <React.Fragment>
      <CardsTableRow>
        <CardsTableColumn
          className="text-center"
          style={{ verticalAlign: 'middle' }}
          key={`hot-checkbox-${record?.id}`}
        >
          <TableCheckbox
            name={`hot-checkbox-${record?.id}`}
            id={record?.id}
            size={'sm'}
            className="d-flex"
            checked={selectedItems?.has(record?.id?.toString())}
            disabled={isAgent}
            onChange={(event) => {
              if (selectedItems?.has(record?.id?.toString())) {
                setSelectedItems((prev) => {
                  prev.delete(record?.id?.toString());
                  return new Set(prev);
                });
              } else {
                setSelectedItems((prev) => {
                  prev.add(record?.id?.toString());
                  return new Set(prev);
                });
              }
            }}
          />
        </CardsTableColumn>
        <CardsTableColumn className="text-center">
          <span className="badge badge-dot has-bg bg-primary">{leadSource}</span>
        </CardsTableColumn>
        <CardsTableColumn className="text-center">
          {isAdmin ? (
            <Link to={`/user-profile/${record?.id ?? record?._id}/user-profile-setting`} target="_blank">
              {leadName}
              <Icon name={'external'} className="ms-2" />
            </Link>
          ) : (
            <span>{leadName}</span>
          )}
        </CardsTableColumn>
        {isAdmin && <CardsTableColumn className="text-center">
          <span>{leadPhone}</span>
        </CardsTableColumn>}
        <CardsTableColumn className="p-0" style={{ maxWidth: 'fit-content' }}>
          <Row>
            <Col sm={12} lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <UserAvatar
                image={lastAgent?.photo ? getPublicDocumentUrl(lastAgent?.photo) : null}
                size={'sm'}
                imageAlt={lastAgent?.name}
                text={findUpper(lastAgent?.name)}
              />
            </Col>
            <Col sm={0} lg={8} style={{ placeItems: 'start' }}>
              <div className="d-none d-lg-flex flex-column justify-content-center">
                <span className="lead-text">{lastAgent?.name}</span>
                <span className="sub-text">{lastAgent?.phone}</span>
              </div>
            </Col>
          </Row>
        </CardsTableColumn>
        <CardsTableColumn className="text-center">
          <span>
            {lastExpirationDate
              ? getTimeUntil(addMilliseconds(new Date(), -(Date.now() - new Date(lastExpirationDate).getTime())))
              : '-'}
          </span>
        </CardsTableColumn>
        <CardsTableColumn className="text-center">
          <span>{getPlural(numberOfExpirations, 'time')}</span>
        </CardsTableColumn>
        <CardsTableColumn className="text-end">
          <Button
            color={'primary'}
            size="sm"
            className="btn btn-dim me-1"
            disabled={assignmentMutation?.status === 'loading'}
            onClick={(e) => {
              e.stopPropagation();
              isAdmin ? toggleModal('admin') : toggleModal('agent');
            }}
          >
            {assignmentMutation?.status === 'loading'
              ? <span className='d-flex align-items-center'><span>Loading</span> <Spinner size={'sm'} className="ms-2" /></span>
              : isAdmin ? 'Assign' : 'Assign to me'}
          </Button>
        </CardsTableColumn>
      </CardsTableRow>
      {modalState?.agent && (
        <ConfirmationModal
          title={'Assign Lead?'}
          isOpen={modalState?.agent}
          toggleFn={() => toggleModal('agent')}
          loadingState={assignmentMutation?.status === 'loading'}
          actionFn={() => assignSingleLead(leadId)}
          dimButtons
        >
          <p>Are you sure you want to assign <span className='fw-bold'>{leadName}</span> to yourself?</p>
        </ConfirmationModal>
      )}
      {modalState?.admin && (
        <Suspense fallback={<LoaderModal />}>
          <AdminAssignmentModal
            isOpen={modalState?.admin}
            toggleFn={() => toggleModal('admin')}
            loadingState={assignmentMutation?.status === 'loading'}
            userIds={[leadId]}
            actionFn={assignSingleLead}
          />
        </Suspense>
      )}
    </React.Fragment>
  );
};

export default ExpiredLeadsView;
